/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
/* ---- previous/next buttons ---- */
/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  margin: 0;
}

/* Remove padding and list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

/* Anchor elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

hr {
  clear: both;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #ccc;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 1ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-display: swap;
  font-family: "EuropaSansLight";
  src: url("/assets/dist/fonts/poppins/Poppins-VariableFont_wght-subset.woff2") format("woff2-variations"), url("/assets/dist/fonts/poppins/Poppins-VariableFont_wght-subset.ttf") format("truetype-variations");
  font-style: normal;
  font-variation-settings: "wght" 320;
  font-weight: 320;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-display: swap;
  font-family: "EuropaSansLightItalic";
  src: url("/assets/dist/fonts/poppins/Poppins-Italic-VariableFont_wght-subset.woff2") format("woff2-variations"), url("/assets/dist/fonts/poppins/Poppins-Italic-VariableFont_wght-subset.ttf") format("truetype-variations");
  font-style: normal;
  font-variation-settings: "wght" 320;
  font-weight: 320;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-display: swap;
  font-family: "EuropaSansRegular";
  src: url("/assets/dist/fonts/poppins/Poppins-VariableFont_wght-subset.woff2") format("woff2-variations"), url("/assets/dist/fonts/poppins/Poppins-VariableFont_wght-subset.ttf") format("truetype-variations");
  font-style: normal;
  font-variation-settings: "wght" 400;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-display: swap;
  font-family: "EuropaSansRegularItalic";
  src: url("/assets/dist/fonts/poppins/Poppins-Italic-VariableFont_wght-subset.woff2") format("woff2-variations"), url("/assets/dist/fonts/poppins/Poppins-Italic-VariableFont_wght-subset.ttf") format("truetype-variations");
  font-style: normal;
  font-variation-settings: "wght" 400;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-display: swap;
  font-family: "EuropaSerifDisplayRegular";
  src: url("/assets/dist/fonts/noto-serif-display/NotoSerifDisplay-Regular.woff2") format("woff2"), url("/assets/dist/fonts/noto-serif-display/NotoSerifDisplay-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-display: swap;
  font-family: "EuropaSerifDisplayRegularItalic";
  src: url("/assets/dist/fonts/noto-serif-display/NotoSerifDisplay-Italic.woff2") format("woff2"), url("/assets/dist/fonts/noto-serif-display/NotoSerifDisplay-Italic.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
.sans-light {
  font-family: "EuropaSansLight", sans-serif !important;
  font-feature-settings: "ss02";
}

.sans-light-italic {
  font-family: "EuropaSansLightItalic", sans-serif !important;
  font-feature-settings: "ss02";
}

.sans-regular {
  font-family: "EuropaSansRegular", sans-serif !important;
  font-feature-settings: "ss02";
}

.sans-regular-italic {
  font-family: "EuropaSansRegularItalic", sans-serif !important;
  font-feature-settings: "ss02";
}

.serif-display-regular {
  font-family: "EuropaSerifDisplayRegular", serif !important;
  letter-spacing: -0.02em;
}

.serif-display-regular-italic {
  font-family: "EuropaSerifDisplayRegularItalic", serif !important;
}

body,
input,
button,
textarea,
select {
  font-family: "EuropaSansRegular", sans-serif;
  font-feature-settings: "ss02";
  font-size: 16px;
  letter-spacing: -0.01em;
  font-weight: normal;
  font-feature-settings: "liga", "kern";
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "EuropaSerifDisplayRegular", serif;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 1.2em;
  margin-top: 0;
  margin-bottom: 30px;
}

h1 {
  font-size: 13.2vw !important;
  line-height: 1em;
}
@media (max-width: 100vw) {
  h1 {
    font-size: 13.2vw !important;
  }
}
@media (min-width: 1439.3939393939px) {
  h1 {
    font-size: 190px !important;
  }
}

h2 {
  font-size: 9vw !important;
  letter-spacing: -0.02em;
}
@media (max-width: 100vw) {
  h2 {
    font-size: 9vw !important;
  }
}
@media (min-width: 1444.4444444444px) {
  h2 {
    font-size: 130px !important;
  }
}

h3 {
  font-size: 7.7vw !important;
  letter-spacing: -0.01em;
}
@media (max-width: 100vw) {
  h3 {
    font-size: 7.7vw !important;
  }
}
@media (min-width: 1428.5714285714px) {
  h3 {
    font-size: 110px !important;
  }
}

h4 {
  font-size: 7vw !important;
}
@media (max-width: 100vw) {
  h4 {
    font-size: 7vw !important;
  }
}
@media (min-width: 1428.5714285714px) {
  h4 {
    font-size: 100px !important;
  }
}

h5 {
  font-size: 6.3vw !important;
}
@media (max-width: 100vw) {
  h5 {
    font-size: 6.3vw !important;
  }
}
@media (min-width: 1428.5714285714px) {
  h5 {
    font-size: 90px !important;
  }
}

h6 {
  font-size: 4.2vw !important;
}
@media (max-width: 100vw) {
  h6 {
    font-size: 4.2vw !important;
  }
}
@media (min-width: 1428.5714285714px) {
  h6 {
    font-size: 60px !important;
  }
}

p:not(:last-child) {
  margin-bottom: 1em;
}

a {
  color: #000;
}
a:not(.no-underline) {
  position: relative;
}
a:not(.no-underline):before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  transform-origin: right center;
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
}
a:not(.no-underline):hover:before, a:not(.no-underline).active:before {
  transform: translateX(-50%) scaleX(1);
}

form label {
  margin-bottom: 15px;
  font-family: "EuropaSansRegular", sans-serif;
  font-feature-settings: "ss02";
  font-size: 18px;
}
form input,
form select,
form textarea {
  margin-bottom: 30px;
  border: 2px solid #000;
  padding: 10px;
  font-size: 18px;
}
form button {
  border: 2px solid #000;
  padding: 10px 30px;
  color: #fff;
  font-family: "EuropaSansRegular", sans-serif;
  font-feature-settings: "ss02";
  font-size: 18px;
  text-transform: uppercase;
  background-color: #000;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
}
form button:hover {
  color: #000;
  background-color: #fff;
}

body.theme-dark {
  color: #fff;
}
body.theme-dark span,
body.theme-dark p,
body.theme-dark a {
  color: #fff;
}
body.theme-dark input,
body.theme-dark button,
body.theme-dark textarea,
body.theme-dark select {
  color: #fff;
  border-color: #fff;
  background-color: #000;
}
body.theme-dark a:before {
  background-color: #ccc;
}

:root {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
}

[data-router-wrapper] {
  padding-top: 200px;
}
@media screen and (max-width: 767px) {
  [data-router-wrapper] {
    padding-top: 143px;
  }
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.5s 0.1s;
}

body.loading {
  cursor: wait;
}

body:not(.loading) .cover {
  opacity: 0;
  pointer-events: none;
}

.lazypicture {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.lazypicture img {
  display: block;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

.lazyfade {
  opacity: 0;
  transition: opacity 2s;
}

.lazyfade.lazyloaded {
  opacity: 1;
}

.lazyfadeDelay {
  opacity: 0;
  transition: opacity 2s 0.5s;
}

.lazyfadeDelay.lazyloaded {
  opacity: 1;
}

.lazyblur {
  opacity: 0.5;
  filter: blur(10px);
  transition: opacity 0.4s;
}

.lazyblur.lazyloaded {
  opacity: 1;
  filter: blur(0);
}

html.has-scroll-smooth:not(.has-scroll-init) {
  cursor: wait;
  overflow: hidden;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.c-scrollbar {
  position: absolute;
  top: 0;
  right: -2px;
  z-index: 4;
  width: 11px;
  height: 100vh;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}

.c-scrollbar:hover {
  transform: scaleX(2);
}

.c-scrollbar:hover,
.has-scroll-dragging .c-scrollbar,
.has-scroll-scrolling .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #000;
  opacity: 1;
  width: 3px;
  margin: 2px;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: grabbing;
}

.lazypicture {
  display: block;
  width: 100%;
}
.lazypicture img {
  display: block;
  width: 100%;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

.lazyfade {
  opacity: 0;
  transition: opacity 1s;
}

.lazyfade.lazyloaded {
  opacity: 1;
}

.lazyblur {
  opacity: 0.5;
  filter: blur(10px);
  transition: opacity 0.4s;
}

.lazyblur.lazyloaded {
  opacity: 1;
  filter: blur(0);
}

@media screen and (max-width: 1024px) {
  .hide-on-tablet {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
}
.min-vh-100 {
  min-height: 100vh;
}

.pointer-events-none {
  pointer-events: none;
}

.select-none {
  user-select: none;
}

.block {
  display: block;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.overflow-auto {
  overflow: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.underline {
  display: inline-block;
  position: relative;
}
.underline:before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.letter-spacing-normal {
  letter-spacing: normal;
}

.image-left {
  clear: both;
  float: left;
  margin-right: 30px;
}

.image-right {
  clear: both;
  float: right;
  margin-left: 30px;
}

.image-center {
  text-align: center;
}

[data-router-view] {
  width: 100%;
}

.word {
  display: inline-block;
  opacity: 0;
  transform: translateY(1em);
}

.is-inview .word {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 2s, transform 1.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.is-inview .word:nth-of-type(1) {
  transition-delay: 0.3s;
}
.is-inview .word:nth-of-type(2) {
  transition-delay: 0.4s;
}
.is-inview .word:nth-of-type(3) {
  transition-delay: 0.5s;
}
.is-inview .word:nth-of-type(4) {
  transition-delay: 0.6s;
}
.is-inview .word:nth-of-type(5) {
  transition-delay: 0.7s;
}
.is-inview .word:nth-of-type(6) {
  transition-delay: 0.8s;
}
.is-inview .word:nth-of-type(7) {
  transition-delay: 0.9s;
}
.is-inview .word:nth-of-type(8) {
  transition-delay: 1s;
}
.is-inview .word:nth-of-type(9) {
  transition-delay: 1.1s;
}
.is-inview .word:nth-of-type(10) {
  transition-delay: 1.2s;
}
.is-inview .word:nth-of-type(11) {
  transition-delay: 1.3s;
}
.is-inview .word:nth-of-type(12) {
  transition-delay: 1.4s;
}
.is-inview .word:nth-of-type(13) {
  transition-delay: 1.5s;
}
.is-inview .word:nth-of-type(14) {
  transition-delay: 1.6s;
}
.is-inview .word:nth-of-type(15) {
  transition-delay: 1.7s;
}
.is-inview .word:nth-of-type(16) {
  transition-delay: 1.8s;
}
.is-inview .word:nth-of-type(17) {
  transition-delay: 1.9s;
}
.is-inview .word:nth-of-type(18) {
  transition-delay: 2s;
}
.is-inview .word:nth-of-type(19) {
  transition-delay: 2.1s;
}
.is-inview .word:nth-of-type(20) {
  transition-delay: 2.2s;
}

body.theme-dark {
  background-color: #000;
}
body.theme-dark .cover {
  background-color: #000;
}
body.theme-dark .c-scrollbar_thumb {
  background-color: #fff;
}
body.theme-dark .underline:before {
  background-color: #fff;
}

.core-header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  width: 100%;
  font-size: 18px;
}
body.scrolled .core-header .core-header-content {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
}
.core-header .core-header-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px 60px;
  background-color: #fff;
  transition: padding 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0.3s;
}
.core-header .core-logo {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-family: "EuropaSansRegular", sans-serif;
  letter-spacing: 0;
}
.core-header .core-logo .underline:before {
  width: calc(100% + 3px);
}
.core-header .core-quick-links a,
.core-header .core-nav-links a {
  position: relative;
  display: inline-block;
  margin: 0 15px;
  color: #000;
  text-transform: uppercase;
}
.core-header .core-quick-links a:first-of-type,
.core-header .core-nav-links a:first-of-type {
  margin-left: 0;
}
.core-header .core-quick-links a:last-of-type,
.core-header .core-nav-links a:last-of-type {
  margin-right: 0;
}
.core-header .core-quick-links {
  position: absolute;
  width: calc(100% - 120px);
  text-align: center;
}
.core-header .core-nav-links {
  position: relative;
  z-index: 1;
}
.core-header .btn-mobile-nav {
  display: none;
}
.core-header .mobile-nav-menu {
  display: none;
}
.core-header #dark-toggle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000;
  cursor: pointer;
}
@media screen and (max-width: 1023px) {
  .core-header {
    left: 0;
    transform: none;
  }
  .core-header .core-quick-links,
  .core-header .core-nav-links {
    display: none;
  }
  .core-header .core-header-content {
    z-index: 2;
    padding: 15px 17px 15px 30px;
    background-color: #fff;
    transition: none;
  }
  .core-header #dark-toggle {
    z-index: 2;
  }
  .core-header .core-logo {
    z-index: 2;
  }
  .core-header .btn-mobile-nav {
    display: block;
    position: relative;
    z-index: 2;
    width: 52px;
    height: 48px;
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
  }
  .core-header .btn-mobile-nav .lines {
    display: block;
    position: absolute;
    top: 14px;
    left: 14px;
    width: calc(100% - 28px);
    height: calc(100% - 28px);
  }
  .core-header .btn-mobile-nav .line {
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    transform-origin: right center;
  }
  .core-header .btn-mobile-nav .line-1 {
    top: 0;
  }
  .core-header .btn-mobile-nav .line-2 {
    top: calc(50% - 2px);
  }
  .core-header .btn-mobile-nav .line-3 {
    bottom: 1px;
  }
  .core-header .mobile-nav-menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    padding: 138px 30px 60px;
    text-align: right;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .core-header .mobile-nav-menu .link {
    display: inline-block;
    width: 100%;
    font-size: 13.2vw;
    line-height: 1em;
    overflow: hidden;
  }
  .core-header .mobile-nav-menu .link:not(:last-of-type) {
    margin-bottom: 15px;
  }
  .core-header .mobile-nav-menu .link:before {
    display: none;
  }
  .core-header .mobile-nav-menu .link-text {
    display: inline-block;
    opacity: 0;
    transform: translateY(100%);
  }
}

body.theme-dark .core-header-content {
  background-color: #000;
}
body.theme-dark.scrolled .core-header-content {
  background-color: #000;
}
body.theme-dark .core-header .core-header-content:before {
  background-color: rgba(255, 255, 255, 0.1);
}
body.theme-dark .core-header .core-quick-links a,
body.theme-dark .core-header .core-nav-links a {
  color: #fff;
}
body.theme-dark .core-header .btn-mobile-nav .line {
  background-color: #ccc;
}
body.theme-dark .core-header .mobile-nav-menu {
  background-color: #000;
}
body.theme-dark .core-header .mobile-nav-menu .link {
  color: #aaa;
}
body.theme-dark .core-header #dark-toggle {
  background: #555;
}

.core-footer {
  position: relative;
  width: 100%;
  margin-top: 100px;
  padding: 100px 60px;
  border-top: 2px solid #000;
}
.core-footer .core-logo {
  display: inline-block;
  font-family: "EuropaSansRegular", sans-serif;
  line-height: 1.3em;
  letter-spacing: 0;
}
.core-footer .core-logo .underline:before {
  width: calc(100% + 3px);
}
.core-footer .row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
.core-footer .row p {
  margin-top: 0;
}
.core-footer .row-1 p {
  line-height: 1.6em;
  margin-bottom: 30px;
}
.core-footer .row-1 p:first-of-type {
  margin-bottom: 40px;
}
.core-footer .row-2 {
  margin-top: 30px;
  font-family: "EuropaSerifDisplayRegular", serif;
  letter-spacing: 0.01em;
  font-size: 14px;
}
.core-footer .row-2 a:before {
  height: 1px;
}
.core-footer .column {
  width: calc(25% - 50px);
  padding-right: 30px;
}
.core-footer .column.last {
  width: 55px;
  padding-right: 0;
}
.core-footer .view-map + svg,
.core-footer .scroll-top + svg {
  position: relative;
  top: 2px;
  margin-left: 10px;
}
.core-footer .scroll-top {
  display: inline-block;
  cursor: pointer;
}
.core-footer .hide {
  visibility: hidden;
  pointer-events: none;
  user-select: none;
}
@media screen and (min-width: 768px) {
  body.has-scroll-smooth:not(.scrolled) .core-footer {
    visibility: hidden !important;
    pointer-events: none;
    user-select: none;
  }
  body.has-scroll-smooth.scrolled .core-footer {
    visibility: visible;
    pointer-events: auto;
    user-select: auto;
  }
}
@media screen and (max-width: 1050px) {
  .core-footer {
    padding: 60px;
  }
  .core-footer .column {
    width: 100%;
  }
  .core-footer .row-1 {
    flex-wrap: wrap;
  }
  .core-footer .row-1 .column {
    width: 50%;
    margin-bottom: 30px;
  }
  .core-footer .row-1 p:first-of-type {
    margin-bottom: 30px;
  }
  .core-footer .row-2 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 767px) {
  .core-footer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .core-footer .row-1 .column {
    width: 100%;
  }
  .core-footer .row-2 {
    margin-top: 0;
  }
}

body.theme-dark .core-footer {
  border-color: #555;
}
body.theme-dark .core-footer a:before {
  background-color: #777;
}
body.theme-dark .core-footer .row-1 a.core-logo,
body.theme-dark .core-footer .row-1 a.core-logo span,
body.theme-dark .core-footer .row-1 p:first-of-type {
  color: #ccc;
}
body.theme-dark .core-footer .row-1 a.core-logo span:before {
  background-color: #ccc;
}
body.theme-dark .core-footer .row-1 a:not(.core-logo),
body.theme-dark .core-footer .row-1 p:not(:first-of-type) {
  color: #777;
}
body.theme-dark .core-footer .row-1 .column.last a {
  color: #ccc;
}
body.theme-dark .core-footer .row-2 a,
body.theme-dark .core-footer .row-2 p,
body.theme-dark .core-footer .row-2 .copyright {
  color: #555;
}
body.theme-dark .core-footer .view-map + svg path {
  fill: #777;
}
body.theme-dark .core-footer .scroll-top + svg path {
  fill: #fff;
}

[data-page=styleguide] [data-router-view=styleguide] {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}
[data-page=styleguide] [data-router-view=styleguide] .page-heading {
  padding-bottom: 100px;
}
@media screen and (max-width: 1024px) {
  [data-page=styleguide] [data-router-view=styleguide] {
    padding-left: 30px;
    padding-right: 30px;
  }
  [data-page=styleguide] [data-router-view=styleguide] .page-heading {
    padding-bottom: 50px;
  }
}

[data-page=home] .wrapper {
  padding-left: 60px;
  padding-right: 60px;
}
[data-page=home] .hero {
  position: relative;
  z-index: 2;
  pointer-events: none;
  user-select: none;
}
[data-page=home] .hero h1 {
  margin-bottom: 1em;
  letter-spacing: -0.05em;
}
[data-page=home] .exhibitions {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vw;
  min-height: 100vh;
}
[data-page=home] .exhibitions-info {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  pointer-events: none;
}
[data-page=home] .exhibit-name {
  user-select: none;
}
[data-page=home] .exhibit-date {
  user-select: none;
}
[data-page=home] .exhibitions-title-container {
  width: 100%;
}
[data-page=home] .exhibitions-count-container {
  width: 100%;
}
[data-page=home] .exhibitions-info .title,
[data-page=home] .exhibitions-info .count {
  user-select: none;
  opacity: 0;
}
[data-page=home] .exhibitions-info .title.is-inview,
[data-page=home] .exhibitions-info .count.is-inview {
  opacity: 1;
  transition: opacity 2s;
}
[data-page=home] .exhibit {
  position: relative;
  z-index: 1;
  display: flex;
  width: 45%;
}
[data-page=home] .exhibit-odd {
  justify-content: flex-start;
}
[data-page=home] .exhibit-even {
  justify-content: flex-end;
}
[data-page=home] .exhibit-2 {
  transform: translateY(-8vh);
}
[data-page=home] .exhibit-3 {
  justify-content: flex-end;
  transform: translateY(-6vh);
}
[data-page=home] .exhibit-4 {
  justify-content: flex-start;
  transform: translateY(-4vh);
}
[data-page=home] .exhibit-odd .exhibit-image {
  transform: translateX(-100px);
}
[data-page=home] .exhibit-even .exhibit-image {
  transform: translateX(100px);
}
[data-page=home] .container {
  width: 100%;
}
[data-page=home] .container .exhibit-image {
  user-select: none;
  opacity: 0;
}
[data-page=home] .container.is-inview .exhibit-image {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 2s, transform 2s;
}
[data-page=home] .container.is-inview .exhibit-name,
[data-page=home] .container.is-inview .exhibit-date {
  opacity: 1;
  transform: translateY(0);
}
[data-page=home] .container.is-inview .exhibit-name {
  transition: opacity 1s, transform 1s;
}
[data-page=home] .container.is-inview .exhibit-date {
  transition: opacity 1s 0.1s, transform 1s 0.1s;
}
[data-page=home] .exhibit-link {
  display: block;
  width: 100%;
}
[data-page=home] .exhibit-odd .exhibit-link:hover .exhibit-image img {
  transform: scale(1.04) rotate(0.4deg);
}
[data-page=home] .exhibit-even .exhibit-link:hover .exhibit-image img {
  transform: scale(1.04) rotate(-0.4deg);
}
[data-page=home] .exhibit-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  width: 100%;
  margin-bottom: 30px;
  background-color: #efefef;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
[data-page=home] .exhibit-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1) rotate(0deg);
  transition: opacity 1s, transform 2.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
[data-page=home] .exhibit-info {
  padding-left: 30px;
  padding-right: 30px;
}
[data-page=home] .exhibit-even .exhibit-info {
  text-align: right;
}
[data-page=home] .exhibit-name {
  text-transform: uppercase;
  opacity: 0;
  transform: translateY(100%);
}
[data-page=home] .exhibit-date {
  font-family: "EuropaSansLight", sans-serif;
  opacity: 0;
  transform: translateY(100%);
}
@media screen and (max-width: 1100px) {
  [data-page=home] .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 767px) {
  [data-page=home] .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  [data-page=home] .hero h1 {
    margin-bottom: 0;
  }
  [data-page=home] .exhibitions {
    min-height: calc(100vh - 78px);
  }
  [data-page=home] .exhibitions-info {
    display: none;
  }
  [data-page=home] .exhibit-1 {
    transform: translateY(-2vh);
  }
  [data-page=home] .exhibit-2 {
    transform: translateY(10vh);
  }
  [data-page=home] .exhibit-3 {
    transform: translateY(-12vh);
  }
  [data-page=home] .exhibit-4 {
    transform: translateY(-4vh);
  }
}

html.has-scroll-scrolling [data-page=home] .container.is-inview .exhibit-link:not(:hover) .exhibit-name,
html.has-scroll-scrolling [data-page=home] .container.is-inview .exhibit-link:not(:hover) .exhibit-date {
  opacity: 0 !important;
  transform: translateY(100%) !important;
}

body.theme-dark[data-page=home] .hero span {
  color: #fff;
}
body.theme-dark[data-page=home] .exhibitions-info .title,
body.theme-dark[data-page=home] .exhibitions-info .count {
  color: #333;
}
body.theme-dark[data-page=home] .exhibit-image {
  background-color: #000;
}
body.theme-dark[data-page=home] .exhibit-name span {
  color: #aaa;
}
body.theme-dark[data-page=home] .exhibit-date span {
  color: #555;
}

[data-page=exhibitions] [data-router-wrapper] {
  padding-top: 0 !important;
}
[data-page=exhibitions] .core-footer {
  margin-top: 0;
}
[data-page=exhibitions] [data-router-view=exhibitions] .exhibitions-container {
  position: relative;
  padding: 200px 60px 150px;
  color: #fff;
  background-color: #000;
}
[data-page=exhibitions] [data-router-view=exhibitions] .hero-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
}
[data-page=exhibitions] [data-router-view=exhibitions] .hero-image-container {
  width: 100%;
  max-width: 1440px;
  margin-top: -75px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
[data-page=exhibitions] [data-router-view=exhibitions] .hero-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
[data-page=exhibitions] [data-router-view=exhibitions] .hero-image picture,
[data-page=exhibitions] [data-router-view=exhibitions] .hero-image picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
[data-page=exhibitions] [data-router-view=exhibitions] .hero-image picture img {
  object-fit: cover;
}
[data-page=exhibitions] [data-router-view=exhibitions] .btn-down {
  position: absolute;
  bottom: -25px;
  left: calc(50% - 25px);
  z-index: 1;
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 100%;
  padding: 15px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
}
[data-page=exhibitions] [data-router-view=exhibitions] .btn-down svg {
  vertical-align: middle;
}
[data-page=exhibitions] [data-router-view=exhibitions] .heading-block {
  max-width: 1280px;
}
[data-page=exhibitions] [data-router-view=exhibitions] .heading-block .heading {
  border-bottom: 2px solid #000;
  padding-bottom: 100px;
  font-family: "EuropaSerifDisplayRegular", serif;
}
@media screen and (max-width: 767px) {
  [data-page=exhibitions] [data-router-view=exhibitions] .heading-block {
    padding-left: 30px;
    padding-right: 30px;
  }
  [data-page=exhibitions] [data-router-view=exhibitions] .heading-block .heading {
    padding-bottom: 60px;
  }
}
[data-page=exhibitions] [data-router-view=exhibitions] .rich-text-block {
  max-width: 1280px;
  text-align: center;
}
[data-page=exhibitions] [data-router-view=exhibitions] .rich-text-block .rich-text {
  border-top: 0;
  padding-top: 0;
}
[data-page=exhibitions] [data-router-view=exhibitions] .rich-text-block h4 {
  font-family: "EuropaSerifDisplayRegular", serif;
  font-size: 7vw !important;
  line-height: 1em;
}
@media (max-width: 100vw) {
  [data-page=exhibitions] [data-router-view=exhibitions] .rich-text-block h4 {
    font-size: 7vw !important;
  }
}
@media (min-width: 1428.5714285714px) {
  [data-page=exhibitions] [data-router-view=exhibitions] .rich-text-block h4 {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 1280px) {
  [data-page=exhibitions] [data-router-view=exhibitions] .hero-image-container {
    margin-top: -50px;
  }
}
@media screen and (max-width: 1024px) {
  [data-page=exhibitions] [data-router-view=exhibitions] .exhibitions-container {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  [data-page=exhibitions] [data-router-view=exhibitions] .hero-image-container {
    margin-top: -35px;
  }
}
@media screen and (max-width: 768px) {
  [data-page=exhibitions] [data-router-view=exhibitions] .hero-image-container {
    margin-top: -34px;
  }
}
@media screen and (max-width: 767px) {
  [data-page=exhibitions] [data-router-view=exhibitions] .exhibitions-container {
    padding: 120px 30px 60px;
  }
  [data-page=exhibitions] [data-router-view=exhibitions] .hero-image-container {
    margin-top: -15px;
  }
}
[data-page=exhibitions].scrolled .core-header-content {
  background-color: rgba(0, 0, 0, 0.95);
}
[data-page=exhibitions].scrolled .core-header,
[data-page=exhibitions] .core-header {
  color: #fff;
  background-color: #000;
}
[data-page=exhibitions].scrolled .core-header .core-logo,
[data-page=exhibitions] .core-header .core-logo {
  color: #fff;
}
[data-page=exhibitions].scrolled .core-header .core-logo .underline:before,
[data-page=exhibitions] .core-header .core-logo .underline:before {
  background-color: #fff;
}
[data-page=exhibitions].scrolled .core-header .core-header-content,
[data-page=exhibitions] .core-header .core-header-content {
  background-color: #000;
}
[data-page=exhibitions].scrolled .core-header .core-header-content:before,
[data-page=exhibitions] .core-header .core-header-content:before {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-page=exhibitions].scrolled .core-header .core-quick-links a,
[data-page=exhibitions].scrolled .core-header .core-nav-links a,
[data-page=exhibitions] .core-header .core-quick-links a,
[data-page=exhibitions] .core-header .core-nav-links a {
  color: #fff;
}
[data-page=exhibitions].scrolled .core-header .core-quick-links a:before,
[data-page=exhibitions].scrolled .core-header .core-nav-links a:before,
[data-page=exhibitions] .core-header .core-quick-links a:before,
[data-page=exhibitions] .core-header .core-nav-links a:before {
  background-color: #fff;
}
[data-page=exhibitions].scrolled .core-header .btn-mobile-nav .line,
[data-page=exhibitions] .core-header .btn-mobile-nav .line {
  background-color: #fff;
}
[data-page=exhibitions].scrolled .core-header .mobile-nav-menu,
[data-page=exhibitions] .core-header .mobile-nav-menu {
  background-color: #000;
}
[data-page=exhibitions].scrolled .core-header .mobile-nav-menu .link,
[data-page=exhibitions] .core-header .mobile-nav-menu .link {
  color: #fff;
}
[data-page=exhibitions].scrolled .core-header #dark-toggle,
[data-page=exhibitions] .core-header #dark-toggle {
  background: #555;
}

body.theme-dark[data-page=exhibitions] [data-router-view=exhibitions] .heading-block .heading {
  border-bottom-color: #fff;
}

[data-page=exhibitions] [data-router-view=exhibit].no-blocks {
  padding-bottom: 100px;
}
[data-page=exhibitions] [data-router-view=exhibit].no-blocks .hero-info {
  justify-content: center;
  border-bottom: 0;
}
[data-page=exhibitions] [data-router-view=exhibit] .exhibit-container {
  padding-top: 200px;
  padding-left: 60px;
  padding-right: 60px;
}
[data-page=exhibitions] [data-router-view=exhibit] .heading {
  position: relative;
  z-index: 1;
  margin-bottom: 100px;
  font-family: "EuropaSansLight", sans-serif;
  text-align: center;
  user-select: none;
}
[data-page=exhibitions] [data-router-view=exhibit] .hero-image-container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 45px;
  box-shadow: 0 10px 70px -40px rgb(0, 0, 0);
  overflow: hidden;
}
[data-page=exhibitions] [data-router-view=exhibit] .hero-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
[data-page=exhibitions] [data-router-view=exhibit] .hero-image picture,
[data-page=exhibitions] [data-router-view=exhibit] .hero-image picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
[data-page=exhibitions] [data-router-view=exhibit] .hero-image picture img {
  object-fit: cover;
}
[data-page=exhibitions] [data-router-view=exhibit] .hero-info {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin-top: -42px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #000;
}
[data-page=exhibitions] [data-router-view=exhibit] .caption {
  font-size: 12px;
}
[data-page=exhibitions] [data-router-view=exhibit] .dates {
  margin-bottom: 20px;
  font-family: "EuropaSansLight", sans-serif;
  font-size: 58px;
  text-align: center;
}
[data-page=exhibitions] [data-router-view=exhibit] .tickets {
  font-size: 12px;
  text-align: right;
}
@media screen and (max-width: 1024px) {
  [data-page=exhibitions] [data-router-view=exhibit] .exhibit-container {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .heading {
    margin-bottom: 60px;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .caption {
    width: 33%;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .dates {
    width: 54%;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .tickets {
    width: 33%;
  }
}
@media screen and (max-width: 930px) {
  [data-page=exhibitions] [data-router-view=exhibit] .hero-image-container {
    padding: 30px;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .hero-info {
    margin-top: -26px;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .dates {
    margin-bottom: 40px;
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  [data-page=exhibitions] [data-router-view=exhibit] .exhibit-container {
    padding: 120px 30px 0;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .hero-image-container {
    padding: 5px;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .hero-info {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .heading {
    margin-bottom: 30px;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .caption {
    order: 2;
    width: 100%;
    text-align: center;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .dates {
    order: 1;
    width: 100%;
    margin-bottom: 15px;
  }
  [data-page=exhibitions] [data-router-view=exhibit] .tickets {
    order: 3;
    width: 100%;
    text-align: center;
  }
}

body.theme-dark [data-router-view=exhibit] .hero-image-container {
  box-shadow: 0 10px 70px -40px rgba(255, 255, 255, 0.4);
  background-color: #fff;
}
body.theme-dark [data-router-view=exhibit] .hero-info {
  border-bottom-color: #fff;
}

[data-page=visit] [data-router-wrapper] {
  padding-top: 0 !important;
}
[data-page=visit] .core-footer {
  margin-top: 0;
}
[data-page=visit] [data-router-view=visit] .visit-container {
  position: relative;
  color: #fff;
  background-color: #000;
}
[data-page=visit] [data-router-view=visit] .hero-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
}
[data-page=visit] [data-router-view=visit] .hero-image-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
[data-page=visit] [data-router-view=visit] .hero-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
[data-page=visit] [data-router-view=visit] .hero-image picture,
[data-page=visit] [data-router-view=visit] .hero-image picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
[data-page=visit] [data-router-view=visit] .hero-image picture img {
  object-fit: cover;
}
[data-page=visit] [data-router-view=visit] .btn-down {
  position: absolute;
  bottom: -25px;
  left: calc(50% - 25px);
  z-index: 1;
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 100%;
  padding: 15px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
}
[data-page=visit] [data-router-view=visit] .btn-down svg {
  vertical-align: middle;
}
[data-page=visit] [data-router-view=visit] .heading-block {
  max-width: 1280px;
}
[data-page=visit] [data-router-view=visit] .heading-block .heading {
  border-bottom: 2px solid #000;
  padding-bottom: 100px;
  font-family: "EuropaSerifDisplayRegular", serif;
}
@media screen and (max-width: 767px) {
  [data-page=visit] [data-router-view=visit] .heading-block {
    padding-left: 30px;
    padding-right: 30px;
  }
  [data-page=visit] [data-router-view=visit] .heading-block .heading {
    padding-bottom: 60px;
  }
}
[data-page=visit] [data-router-view=visit] .rich-text-block h1, [data-page=visit] [data-router-view=visit] .rich-text-block h2, [data-page=visit] [data-router-view=visit] .rich-text-block h3, [data-page=visit] [data-router-view=visit] .rich-text-block h4, [data-page=visit] [data-router-view=visit] .rich-text-block h5, [data-page=visit] [data-router-view=visit] .rich-text-block h6 {
  margin-bottom: 30px;
}
[data-page=visit] [data-router-view=visit] .google-map-embed-block {
  margin-top: 0;
}
@media screen and (max-width: 1023px) {
  [data-page=visit] [data-router-view=visit] .visit-container {
    margin-top: 78px;
  }
}
[data-page=visit].scrolled .core-header-content {
  background-color: rgba(0, 0, 0, 0.95);
}
[data-page=visit].scrolled .core-header,
[data-page=visit] .core-header {
  color: #fff;
  background-color: #000;
}
[data-page=visit].scrolled .core-header .core-logo,
[data-page=visit] .core-header .core-logo {
  color: #fff;
}
[data-page=visit].scrolled .core-header .core-logo .underline:before,
[data-page=visit] .core-header .core-logo .underline:before {
  background-color: #fff;
}
[data-page=visit].scrolled .core-header .core-header-content,
[data-page=visit] .core-header .core-header-content {
  background-color: #000;
}
[data-page=visit].scrolled .core-header .core-header-content:before,
[data-page=visit] .core-header .core-header-content:before {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-page=visit].scrolled .core-header .core-quick-links a,
[data-page=visit].scrolled .core-header .core-nav-links a,
[data-page=visit] .core-header .core-quick-links a,
[data-page=visit] .core-header .core-nav-links a {
  color: #fff;
}
[data-page=visit].scrolled .core-header .core-quick-links a:before,
[data-page=visit].scrolled .core-header .core-nav-links a:before,
[data-page=visit] .core-header .core-quick-links a:before,
[data-page=visit] .core-header .core-nav-links a:before {
  background-color: #fff;
}
[data-page=visit].scrolled .core-header .btn-mobile-nav .line,
[data-page=visit] .core-header .btn-mobile-nav .line {
  background-color: #fff;
}
[data-page=visit].scrolled .core-header .mobile-nav-menu,
[data-page=visit] .core-header .mobile-nav-menu {
  background-color: #000;
}
[data-page=visit].scrolled .core-header .mobile-nav-menu .link,
[data-page=visit] .core-header .mobile-nav-menu .link {
  color: #fff;
}
[data-page=visit].scrolled .core-header #dark-toggle,
[data-page=visit] .core-header #dark-toggle {
  background: #555;
}

[data-page=news] [data-router-view=news] {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
[data-page=news] [data-router-view=news] .news-container svg {
  position: relative;
  top: 1px;
}
[data-page=news] [data-router-view=news] .link-back {
  margin-left: 5px;
}
[data-page=news] [data-router-view=news] .hero-heading,
[data-page=news] [data-router-view=news] .hero-category-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
}
[data-page=news] [data-router-view=news] .hero-category {
  margin-top: 100px;
  margin-bottom: 0;
  font-family: "EuropaSansLight", sans-serif;
  font-size: 24px;
  text-transform: uppercase;
}
[data-page=news] [data-router-view=news] .hero-category-heading {
  margin-top: 0;
  margin-bottom: 100px;
  padding-bottom: 100px;
  border-bottom: 1px solid #000;
  line-height: 1em;
}
[data-page=news] [data-router-view=news] .news-container {
  padding-left: 60px;
  padding-right: 60px;
}
[data-page=news] [data-router-view=news] .news-container.narrowWidth {
  max-width: 1144px;
  margin-left: auto;
  margin-right: auto;
}
[data-page=news] [data-router-view=news] .news-container.narrowWidth .hero-category-heading {
  text-align: left;
}
[data-page=news] [data-router-view=news] .news-article {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}
[data-page=news] [data-router-view=news] .info-left {
  width: 100%;
}
[data-page=news] [data-router-view=news] .info-right {
  width: 100%;
  padding-left: 60px;
}
[data-page=news] [data-router-view=news] .article-image-link {
  display: block;
  margin-top: 9px;
  box-shadow: 0px 70px 50px -50px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
[data-page=news] [data-router-view=news] .article-image-link:hover img {
  transform: scale(1.025);
}
[data-page=news] [data-router-view=news] .article-image-link img {
  display: block;
  width: 100%;
  transform: scale(1);
  transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);
}
[data-page=news] [data-router-view=news] .article-heading {
  margin-bottom: 20px;
  line-height: 1em;
}
@media screen and (min-width: 1025px) {
  [data-page=news] [data-router-view=news] .article-heading a:not(.no-underline):before {
    bottom: 9px;
  }
}
[data-page=news] [data-router-view=news] .divider {
  margin-left: 10px;
  margin-right: 10px;
}
[data-page=news] [data-router-view=news] .article-summary {
  margin-top: 15px;
  line-height: 1.7em;
}
[data-page=news] [data-router-view=news] .pagination {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  border-top: 1px solid #000;
  text-align: right;
}
[data-page=news] [data-router-view=news] .cta-caption-block.narrowWidth {
  max-width: 1144px;
  padding-left: 60px;
  padding-right: 60px;
}
@media screen and (max-width: 850px) {
  [data-page=news] [data-router-view=news] .news-article {
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
  [data-page=news] [data-router-view=news] .info-right {
    padding-left: 0;
  }
  [data-page=news] [data-router-view=news] .article-image-link {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  [data-page=news] [data-router-view=news] .hero-category {
    margin-top: 30px;
    font-size: 18px;
  }
  [data-page=news] [data-router-view=news] .hero-category-heading {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  [data-page=news] [data-router-view=news] .news-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  [data-page=news] [data-router-view=news] .news-article {
    margin-bottom: 30px;
  }
  [data-page=news] [data-router-view=news] .article-heading {
    font-size: 24px !important;
  }
  [data-page=news] [data-router-view=news] .cta-caption-block.narrowWidth {
    padding-left: 30px;
    padding-right: 30px;
  }
}

body.theme-dark[data-page=news] [data-router-view=news] .pagination {
  border-top-color: #fff;
}

[data-page=news] [data-router-view=newsArticle] .news-article-container {
  width: 100%;
  max-width: 1144px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}
[data-page=news] [data-router-view=newsArticle] .news-article-container svg {
  position: relative;
  top: 1px;
}
[data-page=news] [data-router-view=newsArticle] .link-back {
  margin-left: 5px;
}
[data-page=news] [data-router-view=newsArticle] .hero-heading {
  position: relative;
  z-index: 1;
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1em;
  user-select: none;
}
[data-page=news] [data-router-view=newsArticle] .hero-image {
  display: block;
  max-width: 100%;
  box-shadow: 0px 70px 50px -50px rgba(0, 0, 0, 0.2);
}
[data-page=news] [data-router-view=newsArticle] .cta-caption-block.narrowWidth {
  max-width: 1144px;
  margin-top: 30px;
  padding-left: 60px;
  padding-right: 60px;
}
@media screen and (max-width: 767px) {
  [data-page=news] [data-router-view=newsArticle] .news-article-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  [data-page=news] [data-router-view=newsArticle] .hero-heading {
    margin-top: 60px;
    text-align: left;
  }
  [data-page=news] [data-router-view=newsArticle] .cta-caption-block.narrowWidth {
    padding-left: 30px;
    padding-right: 30px;
  }
}

body.theme-dark[data-page=news] [data-router-view=newsArticle] .news-article-container svg path {
  fill: #fff;
}

[data-page=about] [data-router-view=about] .about-container {
  position: relative;
  z-index: 1;
}
[data-page=about] [data-router-view=about] .hero-heading {
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
}
[data-page=about] [data-router-view=about] .about-container:not(.no-hero-image) .hero-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  margin-bottom: 0;
}
[data-page=about] [data-router-view=about] .hero-image-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
[data-page=about] [data-router-view=about] .hero-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
[data-page=about] [data-router-view=about] .hero-image picture,
[data-page=about] [data-router-view=about] .hero-image picture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
[data-page=about] [data-router-view=about] .hero-image picture img {
  object-fit: cover;
}
[data-page=about] [data-router-view=about] .image-and-headings-block {
  margin-top: -75px;
}
[data-page=about] [data-router-view=about] .image-and-headings-block .lazyfadeDelay {
  transition-delay: 0s;
}
[data-page=about] [data-router-view=about] .heading-block .heading {
  padding-bottom: 100px;
  font-size: 9vw !important;
  letter-spacing: -0.02em;
}
@media (max-width: 100vw) {
  [data-page=about] [data-router-view=about] .heading-block .heading {
    font-size: 9vw !important;
  }
}
@media (min-width: 1444.4444444444px) {
  [data-page=about] [data-router-view=about] .heading-block .heading {
    font-size: 130px !important;
  }
}
@media screen and (max-width: 767px) {
  [data-page=about] [data-router-view=about] .heading-block {
    padding-left: 30px;
    padding-right: 30px;
  }
  [data-page=about] [data-router-view=about] .heading-block .heading {
    padding-bottom: 60px;
  }
}
[data-page=about] [data-router-view=about] .rich-text-block h1, [data-page=about] [data-router-view=about] .rich-text-block h2, [data-page=about] [data-router-view=about] .rich-text-block h3, [data-page=about] [data-router-view=about] .rich-text-block h4, [data-page=about] [data-router-view=about] .rich-text-block h5, [data-page=about] [data-router-view=about] .rich-text-block h6 {
  margin-bottom: 30px;
  font-family: "EuropaSansRegular", sans-serif;
  text-transform: none;
}
@media screen and (max-width: 1024px) {
  [data-page=about] [data-router-view=about] .about-container {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  [data-page=about] [data-router-view=about] .hero-image-container {
    margin-top: -35px;
  }
}
@media screen and (max-width: 768px) {
  [data-page=about] [data-router-view=about] .hero-image-container {
    margin-top: -34px;
  }
}
@media screen and (max-width: 767px) {
  [data-page=about] [data-router-view=about] .about-container {
    padding: 0 30px 60px;
  }
  [data-page=about] [data-router-view=about] .hero-image-container {
    margin-top: -15px;
  }
}

[data-page=contact] [data-router-wrapper] {
  padding-top: 0 !important;
}
[data-page=contact] [data-router-view=contact] .contact-container {
  position: relative;
  padding: 200px 60px 100px;
}
[data-page=contact] [data-router-view=contact] .hero-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
}
[data-page=contact] [data-router-view=contact] .hero-image-container {
  width: 100%;
  max-width: 1440px;
  margin-top: -50px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
[data-page=contact] [data-router-view=contact] .hero-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 33.3333333333%;
}
[data-page=contact] [data-router-view=contact] .hero-image picture {
  height: auto;
}
[data-page=contact] [data-router-view=contact] .heading-block {
  margin-top: 0;
  margin-bottom: 0;
}
[data-page=contact] [data-router-view=contact] .heading-block .heading {
  font-family: "EuropaSerifDisplayRegular", serif;
  line-height: 1.2em;
}
@media screen and (max-width: 767px) {
  [data-page=contact] [data-router-view=contact] .heading-block {
    padding-left: 30px;
    padding-right: 30px;
  }
}
[data-page=contact] [data-router-view=contact] .rich-text-block h1, [data-page=contact] [data-router-view=contact] .rich-text-block h2, [data-page=contact] [data-router-view=contact] .rich-text-block h3, [data-page=contact] [data-router-view=contact] .rich-text-block h4, [data-page=contact] [data-router-view=contact] .rich-text-block h5, [data-page=contact] [data-router-view=contact] .rich-text-block h6 {
  margin-bottom: 30px;
}
@media screen and (max-width: 1280px) {
  [data-page=contact] .hero-image-container {
    margin-top: -50px;
  }
}
@media screen and (max-width: 1024px) {
  [data-page=contact] [data-router-view=contact] .contact-container {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  [data-page=contact] [data-router-view=contact] .hero-image-container {
    margin-top: -35px;
  }
}
@media screen and (max-width: 768px) {
  [data-page=contact] [data-router-view=contact] .hero-image-container {
    margin-top: -34px;
  }
}
@media screen and (max-width: 767px) {
  [data-page=contact] [data-router-view=contact] .contact-container {
    padding: 120px 30px 60px;
  }
  [data-page=contact] [data-router-view=contact] .hero-image-container {
    margin-top: -15px;
  }
  [data-page=contact] [data-router-view=contact] .hero-image {
    padding-bottom: 50%;
  }
}

body.page-404 .cover,
body.page-503 .cover,
body.page-error .cover {
  display: none;
}

.heading-block {
  width: 100%;
  max-width: 1024px;
  margin: 120px auto;
  padding-left: 120px;
  padding-right: 120px;
}
.heading-block .heading {
  font-size: 4vw !important;
  font-family: "EuropaSansLight", sans-serif;
  text-align: center;
}
@media (max-width: 600px) {
  .heading-block .heading {
    font-size: 24px !important;
  }
}
@media (min-width: 1300px) {
  .heading-block .heading {
    font-size: 52px !important;
  }
}
@media screen and (max-width: 1024px) {
  .heading-block {
    margin-top: 90px;
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 767px) {
  .heading-block {
    margin-top: 60px;
    margin-bottom: 0;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.image-and-headings-block {
  margin-top: 150px;
  margin-bottom: 150px;
  padding-left: 60px;
  padding-right: 60px;
}
.image-and-headings-block.whiteText {
  color: #fff;
}
.image-and-headings-block .image-and-headings-container {
  position: relative;
  overflow: hidden;
}
.image-and-headings-block .title-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 200px 60px;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 1s 0.25s;
}
.image-and-headings-block .pre-heading {
  font-family: "EuropaSerifDisplayRegular", serif;
  user-select: none;
}
.image-and-headings-block .main-heading {
  font-family: "EuropaSansRegular";
  user-select: none;
}
.image-and-headings-block.is-highlighted.whiteText .title,
.image-and-headings-block.is-highlighted.whiteText .sub-heading {
  background-color: #000;
}
.image-and-headings-block.is-highlighted .title {
  background-color: #fff;
  padding: 60px;
  font-size: 4.8vw !important;
  max-width: 550px;
}
@media (max-width: 750px) {
  .image-and-headings-block.is-highlighted .title {
    font-size: 36px !important;
  }
}
@media (min-width: 1458.3333333333px) {
  .image-and-headings-block.is-highlighted .title {
    font-size: 70px !important;
  }
}
.image-and-headings-block.is-highlighted .sub-heading {
  bottom: 49px;
  left: auto;
  right: 5px;
  width: auto;
  text-align: right;
  background-color: #fff;
  padding: 12px;
}
.image-and-headings-block .sub-heading {
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  user-select: none;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 1s 0.4s;
}
.image-and-headings-block .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #efefef;
}
.image-and-headings-block:not(.isFirst) .image {
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}
.image-and-headings-block .lazypicture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image-and-headings-block .lazypicture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-and-headings-block .is-inview.title-container {
  opacity: 1;
}
.image-and-headings-block .is-inview.sub-heading {
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .image-and-headings-block {
    margin-top: 60px;
    margin-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .image-and-headings-block.is-highlighted.whiteText .title {
    padding: 30px;
  }
  .image-and-headings-block.is-highlighted.whiteText .sub-heading {
    bottom: 50px;
    width: calc(100% - 10px);
    right: 5px;
    text-align: center;
  }
}

body.is-device .image-and-headings-block .title-container {
  transform: none;
}
@media screen and (max-width: 1024px) {
  body.is-device .image-and-headings-block .title-container {
    padding: 120px 30px;
  }
}
@media screen and (max-width: 767px) {
  body.is-device .image-and-headings-block .title-container {
    padding: 60px 30px;
  }
}

body.theme-dark .image-and-headings-block.whiteText {
  color: #ccc;
}

.stats-and-image-block .text-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: 150px auto 0;
  padding: 130px 30px 100px;
  text-align: center;
}
.stats-and-image-block .text-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 120px);
  height: 2px;
  background-color: #000;
}
.stats-and-image-block .stat-left,
.stats-and-image-block .stat-right {
  width: 10%;
}
.stats-and-image-block .stat-value {
  font-family: "EuropaSansLight", sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  line-height: 1em;
}
.stats-and-image-block .stat-label {
  margin-top: 15px;
  font-family: "EuropaSansRegular", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1em;
}
.stats-and-image-block .info {
  width: 80%;
  padding-left: 60px;
  padding-right: 60px;
}
.stats-and-image-block .info .heading {
  margin-bottom: 0;
  text-transform: uppercase;
  line-height: 1em;
  pointer-events: none;
  user-select: none;
}
.stats-and-image-block .info .description {
  max-width: 650px;
  margin: 50px auto 0;
  line-height: 1.6em;
}
.stats-and-image-block .info .link {
  margin-top: 50px;
}
.stats-and-image-block .image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.stats-and-image-block .lazypicture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .stats-and-image-block .text-container {
    flex-wrap: wrap;
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .stats-and-image-block .text-container:before {
    left: 0;
    transform: translateX(0);
    width: 100%;
  }
  .stats-and-image-block .info {
    order: 1;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .stats-and-image-block .stat-left {
    order: 2;
    width: 50%;
    margin-top: 60px;
  }
  .stats-and-image-block .stat-right {
    order: 3;
    width: 50%;
    margin-top: 60px;
  }
}

body.theme-dark .stats-and-image-block .text-container:before {
  background-color: #fff;
}
body.theme-dark .stats-and-image-block .stat-value {
  color: #aaa;
}
body.theme-dark .stats-and-image-block .stat-label {
  color: #aaa;
}
body.theme-dark .stats-and-image-block .heading {
  color: #fff;
}
body.theme-dark .stats-and-image-block .description p {
  color: #aaa;
}
body.theme-dark .stats-and-image-block .link a {
  color: #fff;
}

.slider-block {
  position: relative;
  width: 100%;
  margin-top: 100px;
}
.slider-block .slider-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  padding-left: 60px;
  padding-right: 60px;
}
.slider-block .slider-link-container {
  flex-shrink: 0;
  min-width: 112x;
}
.slider-block .slider-link {
  text-transform: uppercase;
}
.slider-block .slider-link:before {
  bottom: -1px;
}
.slider-block .slider-heading {
  width: 100%;
  margin-bottom: 0;
  padding-left: 60px;
  padding-right: 60px;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  pointer-events: none;
  user-select: none;
}
.slider-block .slider-arrows {
  flex-shrink: 0;
  min-width: 120px;
  text-align: right;
}
.slider-block .flickity-viewport {
  overflow: visible;
}
.slider-block .btn-slider-prev,
.slider-block .btn-slider-next {
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.slider-block .slider-arrows .divider {
  margin-left: 30px;
  margin-right: 30px;
  color: #e5e5e5;
}
.slider-block .slider-container {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
}
.slider-block .slider {
  position: relative;
  outline: none;
}
.slider-block .slide {
  display: block;
  margin-left: 35px;
  margin-right: 35px;
}
.slider-block .slide:after {
  content: "";
  display: block;
  width: 100%;
  max-width: calc(100vw - 150px);
  height: 3px;
  margin-top: 50px;
  background-color: #e5e5e5;
  transform: scaleY(1);
  transform-origin: center bottom;
  transition: background-color 1s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s;
}
.slider-block .slide:hover:after {
  transform: scaleY(1.5);
}
.slider-block .slide-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}
.slider-block .slide-image {
  display: block;
  width: auto;
  height: auto;
  max-width: calc(100vw - 150px);
  max-height: 500px;
  pointer-events: none;
  user-select: none;
  box-shadow: 0px 50px 50px -50px rgba(0, 0, 0, 0.5);
  transform: scale(1) translateY(0);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 1s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform, box-shadow;
}
.slider-block .slider.slide-wrap .slide {
  margin-left: 45px;
  margin-right: 45px;
  padding-top: 20px;
  will-change: transform;
}
.slider-block .slider.slide-wrap .slide.is-selected:after {
  background-color: #000;
}
.slider-block .slider.slide-wrap .slide.is-selected:hover.is-selected:after {
  transform: scaleY(2);
}
.slider-block .slider.slide-wrap .slide.is-selected .slide-image {
  box-shadow: 0px 70px 50px -50px rgba(0, 0, 0, 0.7);
  transform: scale(1.05) translateY(-0.1%);
}
.slider-block .slide-info {
  margin-top: 60px;
  text-align: center;
}
.slider-block .slide-caption {
  max-width: 500px;
  font-family: "EuropaSansRegular", sans-serif;
  font-size: 12px;
}
.slider-block .slide-title {
  font-family: "EuropaSansRegular", sans-serif;
  font-size: 26px;
  padding-bottom: 20px;
}
.slider-block .slide-news-category {
  font-family: "EuropaSansRegular", sans-serif;
  font-size: 16px;
}
.slider-block .slide-date {
  font-family: "EuropaSansRegular", sans-serif;
  font-size: 16px;
}
.slider-block.is-image .slider-info {
  height: 0;
  margin: 0;
  padding: 0;
}
.slider-block.is-image .slider-link-container,
.slider-block.is-image .slider-heading {
  display: none;
}
.slider-block.is-image .btn-slider-prev {
  position: absolute;
  top: calc(50% - 15px);
  left: 120px;
  z-index: 1;
  border-radius: 100%;
  padding: 15px;
  background-color: #fff;
  transform: translateY(-50%);
}
.slider-block.is-image .btn-slider-prev svg {
  vertical-align: middle;
}
.slider-block.is-image .btn-slider-next {
  position: absolute;
  top: calc(50% - 15px);
  right: 120px;
  z-index: 1;
  border-radius: 100%;
  padding: 15px;
  background-color: #fff;
  transform: translateY(-50%);
}
.slider-block.is-image .btn-slider-next svg {
  vertical-align: middle;
}
.slider-block.is-image .slide:after {
  display: none;
}
@media screen and (max-width: 1024px) {
  .slider-block.is-image .btn-slider-prev {
    top: calc(50% - 30px);
    left: 58px;
  }
  .slider-block.is-image .btn-slider-next {
    top: calc(50% - 30px);
    right: 58px;
  }
}
@media screen and (max-width: 767px) {
  .slider-block.is-image {
    margin-top: 0;
  }
  .slider-block.is-image .btn-slider-prev {
    top: calc(50% - 15px);
    left: 10px;
  }
  .slider-block.is-image .btn-slider-next {
    top: calc(50% - 15px);
    right: 10px;
  }
  .slider-block.is-image .slide-caption {
    max-width: 225px;
  }
}
@media screen and (max-width: 767px) {
  .slider-block {
    margin-top: 60px;
  }
  .slider-block .slider-info {
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .slider-block .slider-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .slider-block .slide {
    margin-left: 30px;
    margin-right: 0;
  }
  .slider-block .slider.slide-wrap .slide {
    margin-left: 25px;
    margin-right: 25px;
  }
  .slider-block .slider-heading {
    order: 1;
    width: 100%;
  }
  .slider-block .slider-arrows {
    order: 3;
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  .slider-block .slide-image-container {
    min-height: 225px;
  }
  .slider-block .slide-info {
    margin-top: 30px;
  }
  .slider-block .slide-title {
    font-size: 18px;
  }
  .slider-block .slide-date {
    font-size: 16px;
  }
}

.slider-block.dark-ui {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #000;
}
.slider-block.dark-ui a.slider-link {
  color: #fff;
}
.slider-block.dark-ui a.slider-link:before {
  background-color: #fff;
}
.slider-block.dark-ui .slider-heading {
  color: #fff;
}
.slider-block.dark-ui .slider-arrows svg path {
  fill: #fff;
}
.slider-block.dark-ui .slider-arrows .divider {
  color: #555;
}
.slider-block.dark-ui .slider.slide-wrap .slide.is-selected .slide-image {
  box-shadow: 0 10px 70px -40px rgba(255, 255, 255, 0.7);
}
.slider-block.dark-ui .slide-title {
  color: #fff;
}
.slider-block.dark-ui .slide-news-category,
.slider-block.dark-ui .slide-date {
  color: #555;
}
.slider-block.dark-ui .slider .slide:after {
  background-color: #222;
}
.slider-block.dark-ui .slider.slide-wrap .slide:after {
  background-color: #222;
}
.slider-block.dark-ui .slider.slide-wrap .slide.is-selected:after {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .slider-block.dark-ui {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

body.theme-dark .slider-block a.slider-link {
  color: #fff;
}
body.theme-dark .slider-block .slider-heading {
  color: #fff;
}
body.theme-dark .slider-block .slider-arrows svg path {
  fill: #fff;
}
body.theme-dark .slider-block .slider-arrows .divider {
  color: #333;
}
body.theme-dark .slider-block .slider.slide-wrap .slide.is-selected .slide-image {
  box-shadow: 0 10px 70px -40px rgba(255, 255, 255, 0.7);
}
body.theme-dark .slider-block .slide-title {
  color: #fff;
}
body.theme-dark .slider-block .slide-news-category {
  color: #777;
}
body.theme-dark .slider-block .slide-date {
  color: #777;
}
body.theme-dark .slider-block .slider .slide:after {
  background-color: #222;
}
body.theme-dark .slider-block .slider.slide-wrap .slide:after {
  background-color: #222;
}
body.theme-dark .slider-block .slider.slide-wrap .slide.is-selected:after {
  background-color: #fff;
}

.rich-text-block {
  width: 100%;
  max-width: 1440px;
  margin: 120px auto;
  padding-left: 60px;
  padding-right: 60px;
}
.rich-text-block.narrowWidth {
  max-width: 1024px;
  padding-left: 120px;
  padding-right: 120px;
}
.rich-text-block .rich-text {
  width: 100%;
}
.rich-text-block .rich-text:after {
  content: "";
  display: table;
  clear: both;
}
.rich-text-block.topBorder .rich-text {
  border-top: 1px solid #000;
  padding-top: 120px;
}
.rich-text-block h1, .rich-text-block h2, .rich-text-block h3, .rich-text-block h4, .rich-text-block h5, .rich-text-block h6 {
  text-transform: uppercase;
  line-height: 1.4em;
  margin-top: 0;
  margin-bottom: 45px;
  padding-top: 15px;
}
.rich-text-block h1 {
  font-size: 4.5vw !important;
}
@media (max-width: 1377.7777777778px) {
  .rich-text-block h1 {
    font-size: 62px !important;
  }
}
@media (min-width: 1600px) {
  .rich-text-block h1 {
    font-size: 72px !important;
  }
}
.rich-text-block h2 {
  font-size: 4.2vw !important;
}
@media (max-width: 1190.4761904762px) {
  .rich-text-block h2 {
    font-size: 50px !important;
  }
}
@media (min-width: 1428.5714285714px) {
  .rich-text-block h2 {
    font-size: 60px !important;
  }
}
.rich-text-block h3 {
  font-size: 3.8vw !important;
}
@media (max-width: 1052.6315789474px) {
  .rich-text-block h3 {
    font-size: 40px !important;
  }
}
@media (min-width: 1263.1578947368px) {
  .rich-text-block h3 {
    font-size: 48px !important;
  }
}
.rich-text-block h4 {
  font-size: 3.2vw !important;
}
@media (max-width: 750px) {
  .rich-text-block h4 {
    font-size: 24px !important;
  }
}
@media (min-width: 1125px) {
  .rich-text-block h4 {
    font-size: 36px !important;
  }
}
.rich-text-block h5 {
  font-size: 2.6vw !important;
}
@media (max-width: 692.3076923077px) {
  .rich-text-block h5 {
    font-size: 18px !important;
  }
}
@media (min-width: 923.0769230769px) {
  .rich-text-block h5 {
    font-size: 24px !important;
  }
}
.rich-text-block h6 {
  font-size: 1.4vw !important;
}
@media (max-width: 857.1428571429px) {
  .rich-text-block h6 {
    font-size: 12px !important;
  }
}
@media (min-width: 1142.8571428571px) {
  .rich-text-block h6 {
    font-size: 16px !important;
  }
}
.rich-text-block p {
  font-family: "EuropaSansLight", sans-serif;
  font-size: 1.6vw !important;
  line-height: 1.8em;
}
@media (max-width: 1000px) {
  .rich-text-block p {
    font-size: 16px !important;
  }
}
@media (min-width: 1125px) {
  .rich-text-block p {
    font-size: 18px !important;
  }
}
.rich-text-block strong {
  font-family: "EuropaSans", sans-serif;
  font-weight: 500;
}
.rich-text-block ul {
  text-indent: 45px;
  font-family: "EuropaSansLight", sans-serif;
  font-size: 1.6vw !important;
  line-height: 1.8em;
}
@media (max-width: 1000px) {
  .rich-text-block ul {
    font-size: 16px !important;
  }
}
@media (min-width: 1125px) {
  .rich-text-block ul {
    font-size: 18px !important;
  }
}
.rich-text-block figure {
  margin: 10px 0 30px 0;
  width: 100%;
}
.rich-text-block figure img, .rich-text-block figure picture {
  background-color: #f7f7f7;
  box-shadow: 0px 70px 50px -50px rgba(0, 0, 0, 0.2);
}
.rich-text-block figure figcaption {
  font-family: "EuropaSansRegular", sans-serif;
  font-size: 12px;
  margin: 10px auto 0 auto;
  max-width: 500px;
  text-align: center;
}
.rich-text-block.layout-imageFullWidth figure {
  margin: 60px 0;
}
.rich-text-block.layout-imageLeft figure {
  float: left;
  width: calc(50% - 30px);
  margin-right: 60px;
}
.rich-text-block.layout-imageRight figure {
  float: right;
  width: calc(50% + 30px);
  margin-left: 60px;
}
.rich-text-block img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1024px) {
  .rich-text-block {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .rich-text-block.topBorder .rich-text {
    padding-top: 60px;
  }
}
@media screen and (max-width: 767px) {
  .rich-text-block {
    padding-left: 30px;
    padding-right: 30px;
  }
  .rich-text-block.narrowWidth {
    padding-left: 30px;
    padding-right: 30px;
  }
  .rich-text-block h1, .rich-text-block h2, .rich-text-block h3, .rich-text-block h4, .rich-text-block h5, .rich-text-block h6 {
    margin-bottom: 30px;
  }
  .rich-text-block ul {
    text-indent: 0;
  }
  .rich-text-block figure {
    width: 100%;
    margin-top: 0;
  }
  .rich-text-block.layout-imageFullWidth figure {
    margin: 30px 0;
  }
  .rich-text-block.layout-imageLeft figure {
    float: none;
    width: 100%;
    margin-right: 0;
  }
  .rich-text-block.layout-imageRight figure {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}

body.theme-dark .rich-text-block.topBorder .rich-text {
  border-top-color: #fff;
}

.cta-caption-block {
  width: 100%;
  max-width: 1440px;
  margin: 120px auto;
  padding-left: 60px;
  padding-right: 60px;
}
.cta-caption-block.narrowWidth {
  max-width: 1024px;
  padding-left: 120px;
  padding-right: 120px;
}
.cta-caption-block .caption-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #000;
  padding-top: 30px;
  font-family: "EuropaSansLight", sans-serif;
}
.cta-caption-block .caption-heading {
  text-transform: uppercase;
}
.cta-caption-block .caption-heading img {
  position: relative;
  top: -2px;
  vertical-align: middle;
  width: auto;
  height: 1em;
  border-radius: 50%;
  overflow: hidden;
}
.cta-caption-block .information img {
  position: relative;
  top: -2px;
  vertical-align: middle;
  width: auto;
  height: 1em;
  border-radius: 50%;
  overflow: hidden;
}
.cta-caption-block .text,
.cta-caption-block .link {
  width: calc(50% - 30px);
}
.cta-caption-block.has-icon .text {
  padding-top: 3px;
}
.cta-caption-block .link {
  text-align: right;
}
.cta-caption-block .link a {
  display: inline;
}
.cta-caption-block .link svg {
  position: relative;
  top: 3px;
  max-width: 22px;
  height: 1em;
}
.cta-caption-block .link a + span svg {
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .cta-caption-block {
    margin-top: 60px;
    margin-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .cta-caption-block.narrowWidth {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cta-caption-block .caption-container {
    flex-wrap: wrap;
  }
  .cta-caption-block .text,
  .cta-caption-block .link {
    width: 100%;
  }
  .cta-caption-block .link {
    margin-top: 15px;
    text-align: left;
  }
  .cta-caption-block .link span {
    display: none;
  }
  .cta-caption-block .link a + span {
    display: inline-block;
  }
}

body.theme-dark .cta-caption-block .caption-container {
  border-top-color: #fff;
}
body.theme-dark .cta-caption-block .link svg path {
  fill: #fff;
}

.featured-entry-block {
  padding-left: 60px;
  padding-right: 60px;
}
.featured-entry-block .featured-entry-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.featured-entry-block .link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.featured-entry-block .link:hover .hero-image .lazypicture {
  transform: scale(1.01);
}
.featured-entry-block .link:hover .title:before {
  transform: scaleX(1);
}
.featured-entry-block .title-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 1s 0.5s;
}
.featured-entry-block .title-container.whiteText {
  color: #fff;
}
.featured-entry-block .title {
  margin-bottom: 0;
  font-family: "EuropaSansRegular";
  text-transform: uppercase;
  user-select: none;
}
.featured-entry-block .title.whiteText {
  color: #fff;
}
.featured-entry-block .title.whiteText.underline:before {
  background-color: #fff;
}
.featured-entry-block .title:before {
  height: 0.7vw;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.featured-entry-block .hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.featured-entry-block .lazypicture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 2.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.featured-entry-block .lazypicture img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.featured-entry-block .is-inview.title-container {
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .featured-entry-block {
    padding-left: 30px;
    padding-right: 30px;
  }
  .featured-entry-block .featured-entry-container {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
  }
  .featured-entry-block .title-container {
    transform: none;
  }
}

body.is-device .featured-entry-block .title-container {
  transform: none;
}

body.theme-dark .featured-entry-block .title.whiteText {
  color: #fff;
}

.form-block {
  width: 100%;
  max-width: 1024px;
  margin: 120px auto;
  padding-left: 120px;
  padding-right: 120px;
}
.form-block .freeform-row {
  display: block;
}
.form-block .freeform-label {
  margin-bottom: 15px;
  font-family: "EuropaSansRegular", sans-serif;
  font-size: 18px;
}
.form-block .freeform-row .freeform-column .freeform-input {
  margin-bottom: 30px;
  border: 2px solid #000;
  padding: 10px;
  font-size: 18px;
}
.form-block .freeform-row button {
  border: 2px solid #000;
  padding: 10px 30px;
  color: #fff;
  font-family: "EuropaSansRegular", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  background-color: #000;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  cursor: pointer;
}
.form-block .freeform-row button:hover {
  color: #000;
  background-color: #fff;
}
@media screen and (min-width: 640px) {
  .form-block .freeform-row {
    display: flex;
  }
}
@media screen and (max-width: 1024px) {
  .form-block {
    margin-top: 90px;
    margin-bottom: 90px;
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (max-width: 767px) {
  .form-block {
    margin-top: 30px;
    margin-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

body.theme-dark .form-block .ff-form-errors {
  background-color: #fff;
}
body.theme-dark .form-block .ff-form-errors p {
  color: #000;
}
body.theme-dark .form-block .freeform-row .freeform-column .freeform-input {
  border-color: #fff;
}
body.theme-dark .form-block .freeform-row button {
  border-color: #fff;
  color: #000;
  background-color: #fff;
}
body.theme-dark .form-block .freeform-row button:hover {
  color: #fff;
  background-color: #000;
}

.google-map-embed-block {
  width: 100%;
  max-width: 1024px;
  margin: 120px auto;
  padding-left: 60px;
  padding-right: 60px;
}
.google-map-embed-block .google-map-embed-container {
  box-shadow: 0px 70px 50px -50px rgba(0, 0, 0, 0.2);
}
.google-map-embed-block iframe {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .google-map-embed-block {
    margin-top: 90px;
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 767px) {
  .google-map-embed-block {
    margin-top: 60px;
    margin-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }
}