*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    margin: 0;
    // scroll-behavior: smooth;
}

// body,
// h1,
// h2,
// h3,
// h4,
// p,
// ul[class],
// ol[class],
// li,
// figure,
// figcaption,
// blockquote,
// dl,
// dd {
//     margin: 0;
// }

/* Remove padding and list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

/* Anchor elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
    font: inherit;
}

hr {
    clear: both;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #ccc;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 1ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 1ms !important;
        scroll-behavior: auto !important;
    }
}
