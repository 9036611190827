.featured-entry-block {

    padding-left: 60px;
    padding-right: 60px;

    .featured-entry-container {
        position: relative;
        @include aspect-ratio(16 9);
        overflow: hidden;
    }

    .link {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:hover {
            .hero-image .lazypicture {
                transform: scale(1.01);
            }

            .title:before {
                transform: scaleX(1);
            }
        }
    }

    .title-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 1s 0.5s;

        &.whiteText {
            color: #fff;
        }
    }

    .title {
        margin-bottom: 0;
        font-family: 'EuropaSansRegular';
        text-transform: uppercase;
        user-select: none;

        &.whiteText {
            color: #fff;

            &.underline:before {
                background-color: #fff;
            }
        }

        &:before {
            height: 0.7vw;
            transform: scaleX(0);
            transform-origin: right center;
            transition: transform 0.3s $ease-in-out-1;
        }
    }

    .hero-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .lazypicture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: transform 2.5s $ease-out-1;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .is-inview {

        &.title-container {
            opacity: 1;
        }

    }

    // Responsive

    @media screen and (max-width: 767px) {

        padding-left: 30px;
        padding-right: 30px;

        .featured-entry-container {
            @include aspect-ratio(4 3);
        }

        .title-container {
            transform: none;
        }

    }

}

body.is-device {

    .featured-entry-block {

        .title-container {
            transform: none;
        }

    }

}


// Themes

body.theme-dark {

    .featured-entry-block {

        .title.whiteText {
            color: #fff;
        }

    }

}
