[data-page="home"] {

    .wrapper {
        padding-left: 60px;
        padding-right: 60px;
    }

    .hero {

        position: relative;
        z-index: 2;
        pointer-events: none;
        user-select: none;

        h1 {
            margin-bottom: 1em;
            letter-spacing: -0.05em;
        }

    }

    // Exhibitions

    .exhibitions {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100vw;
        min-height: 100vh;
    }

    .exhibitions-info {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        pointer-events: none;
    }

    .exhibit-name {
        user-select: none;
    }

    .exhibit-date {
        user-select: none;
    }

    .exhibitions-title-container {
        width: 100%;
    }

    .exhibitions-count-container {
        width: 100%;
    }

    .exhibitions-info {
        .title,
        .count {
            user-select: none;
            opacity: 0;

            &.is-inview {
                opacity: 1;
                transition: opacity 2s;
            }
        }
    }

    .exhibit {
        position: relative;
        z-index: 1;
        display: flex;
        width: 45%;
    }

    .exhibit-odd {
        justify-content: flex-start;
    }

    .exhibit-even {
        justify-content: flex-end;
    }

    .exhibit-2 {
        transform: translateY(-8vh);
    }

    .exhibit-3 {
        justify-content: flex-end;
        transform: translateY(-6vh);
    }

    .exhibit-4 {
        justify-content: flex-start;
        transform: translateY(-4vh);
    }

    .exhibit-odd .exhibit-image {
        transform: translateX(-100px);
    }

    .exhibit-even .exhibit-image {
        transform: translateX(100px);
    }

    .container {

        width: 100%;

        .exhibit-image {
            user-select: none;
            opacity: 0;
        }

        &.is-inview {

            .exhibit-image {
                opacity: 1;
                transform: translateX(0);
                transition: opacity 2s, transform 2s;
            }

            .exhibit-name,
            .exhibit-date {
                opacity: 1;
                transform: translateY(0);
            }

            .exhibit-name {
                transition: opacity 1s, transform 1s;
            }

            .exhibit-date {
                transition: opacity 1s 0.1s, transform 1s 0.1s;
            }

        }

    }

    .exhibit-link {
        display: block;
        width: 100%;
    }

    .exhibit-odd {
        .exhibit-link:hover {
            .exhibit-image img {
                transform: scale(1.04) rotate(0.4deg);
            }
        }
    }

    .exhibit-even {
        .exhibit-link:hover {
            .exhibit-image img {
                transform: scale(1.04) rotate(-0.4deg);
            }
        }
    }

    .exhibit-image {
        position: relative;
        @include aspect-ratio(4 3);
        width: 100%;
        margin-bottom: 30px;
        background-color: #efefef;
        box-shadow: 0 0 60px rgba(0,0,0,0.15);
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1) rotate(0deg);
            transition: opacity 1s, transform 2.5s $ease-out-1;
        }
    }

    .exhibit-info {
        padding-left: 30px;
        padding-right: 30px;
    }

    .exhibit-even .exhibit-info {
        text-align: right;
    }

    .exhibit-name {
        text-transform: uppercase;
        opacity: 0;
        transform: translateY(100%);
    }

    .exhibit-date {
        font-family: 'EuropaSansLight', sans-serif;
        opacity: 0;
        transform: translateY(100%);
    }

    @media screen and (max-width: 1100px) {

        .wrapper {
            padding-left: 30px;
            padding-right: 30px;
        }

    }

    @media screen and (max-width: 767px) {

        .wrapper {
            padding-left: 15px;
            padding-right: 15px;
        }

        .hero h1 {
            margin-bottom: 0;
        }

        .exhibitions {
            min-height: calc(100vh - 78px);
        }

        .exhibitions-info {
            display: none;
        }

        .exhibit-1 {
            transform: translateY(-2vh);
        }

        .exhibit-2 {
            transform: translateY(10vh);
        }

        .exhibit-3 {
            transform: translateY(-12vh);
        }

        .exhibit-4 {
            transform: translateY(-4vh);
        }

    }

}

html.has-scroll-scrolling [data-page="home"] {

    .container.is-inview {
        .exhibit-link:not(:hover) {
            .exhibit-name,
            .exhibit-date {
                opacity: 0 !important;
                transform: translateY(100%) !important;
            }
        }
    }

}

// Themes

body.theme-dark {

    &[data-page=home] {

        .hero span {
            color: #fff;
        }

        .exhibitions-info {
            .title,
            .count {
                color: #333;
            }
        }

        .exhibit-image {
            background-color: #000;
        }

        .exhibit-name {
            span {
                color: #aaa;
            }
        }

        .exhibit-date {
            span {
                color: #555;
            }
        }

    }

}
