// Web Font - Poppins Variable Font Beta

// Light
@font-face {
    font-display: swap;
    font-family: 'EuropaSansLight';
    src: url('/assets/dist/fonts/poppins/Poppins-VariableFont_wght-subset.woff2') format('woff2-variations'),
        url('/assets/dist/fonts/poppins/Poppins-VariableFont_wght-subset.ttf') format('truetype-variations');
    font-style: normal;
    font-variation-settings: 'wght' 320;
    font-weight: 320;
    text-rendering: optimizeLegibility;
}

// Light - Italic
@font-face {
    font-display: swap;
    font-family: 'EuropaSansLightItalic';
    src: url('/assets/dist/fonts/poppins/Poppins-Italic-VariableFont_wght-subset.woff2')
            format('woff2-variations'),
        url('/assets/dist/fonts/poppins/Poppins-Italic-VariableFont_wght-subset.ttf')
            format('truetype-variations');
    font-style: normal;
    font-variation-settings: 'wght' 320;
    font-weight: 320;
    text-rendering: optimizeLegibility;
}

// Regular
@font-face {
    font-display: swap;
    font-family: 'EuropaSansRegular';
    src: url('/assets/dist/fonts/poppins/Poppins-VariableFont_wght-subset.woff2') format('woff2-variations'),
        url('/assets/dist/fonts/poppins/Poppins-VariableFont_wght-subset.ttf') format('truetype-variations');
    font-style: normal;
    font-variation-settings: 'wght' 400;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

// Regular - Italic
@font-face {
    font-display: swap;
    font-family: 'EuropaSansRegularItalic';
    src: url('/assets/dist/fonts/poppins/Poppins-Italic-VariableFont_wght-subset.woff2')
            format('woff2-variations'),
        url('/assets/dist/fonts/poppins/Poppins-Italic-VariableFont_wght-subset.ttf')
            format('truetype-variations');
    font-style: normal;
    font-variation-settings: 'wght' 400;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

// Web Font - Noto Serif Display

// Regular
@font-face {
    font-display: swap;
    font-family: 'EuropaSerifDisplayRegular';
    src: url('/assets/dist/fonts/noto-serif-display/NotoSerifDisplay-Regular.woff2') format('woff2'),
        url('/assets/dist/fonts/noto-serif-display/NotoSerifDisplay-Regular.woff') format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

// Regular - Italic
@font-face {
    font-display: swap;
    font-family: 'EuropaSerifDisplayRegularItalic';
    src: url('/assets/dist/fonts/noto-serif-display/NotoSerifDisplay-Italic.woff2') format('woff2'),
        url('/assets/dist/fonts/noto-serif-display/NotoSerifDisplay-Italic.woff') format('woff');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

.sans-light {
    font-family: 'EuropaSansLight', sans-serif !important;
    font-feature-settings: 'ss02';
}

.sans-light-italic {
    font-family: 'EuropaSansLightItalic', sans-serif !important;
    font-feature-settings: 'ss02';
}

.sans-regular {
    font-family: 'EuropaSansRegular', sans-serif !important;
    font-feature-settings: 'ss02';
}

.sans-regular-italic {
    font-family: 'EuropaSansRegularItalic', sans-serif !important;
    font-feature-settings: 'ss02';
}

.serif-display-regular {
    font-family: 'EuropaSerifDisplayRegular', serif !important;
    letter-spacing: -0.02em;
}

.serif-display-regular-italic {
    font-family: 'EuropaSerifDisplayRegularItalic', serif !important;
}

// Global

body,
input,
button,
textarea,
select {
    font-family: 'EuropaSansRegular', sans-serif;
    font-feature-settings: 'ss02';
    font-size: 16px;
    letter-spacing: -0.01em;
    font-weight: normal;
    font-feature-settings: 'liga', 'kern';
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

// Headings

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'EuropaSerifDisplayRegular', serif;
    font-weight: normal;
    letter-spacing: -0.02em;
    line-height: 1.2em;
    margin-top: 0;
    margin-bottom: 30px;
}

h1 {
    @include responsive-font(13.2vw, 13.2vw, 190px);
    line-height: 1em;
}

h2 {
    @include responsive-font(9vw, 9vw, 130px);
    letter-spacing: -0.02em;
}

h3 {
    @include responsive-font(7.7vw, 7.7vw, 110px);
    letter-spacing: -0.01em;
}

h4 {
    @include responsive-font(7vw, 7vw, 100px);
}

h5 {
    @include responsive-font(6.3vw, 6.3vw, 90px);
}

h6 {
    @include responsive-font(4.2vw, 4.2vw, 60px);
}

// Paragraph

p {
    &:not(:last-child) {
        margin-bottom: 1em;
    }
}

// Anchor

a {
    color: #000;

    &:not(.no-underline) {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: 1px;
            left: 50%;
            transform: translateX(-50%) scaleX(0);
            transform-origin: right center;
            width: 100%;
            height: 2px;
            background-color: #000;
            transition: transform 0.3s $ease-in-out-2;
        }

        &:hover:before,
        &.active:before {
            transform: translateX(-50%) scaleX(1);
        }
    }
}

// Forms

form {
    label {
        margin-bottom: 15px;
        font-family: 'EuropaSansRegular', sans-serif;
        font-feature-settings: 'ss02';
        font-size: 18px;
    }

    input,
    select,
    textarea {
        margin-bottom: 30px;
        border: 2px solid #000;
        padding: 10px;
        font-size: 18px;
    }

    button {
        border: 2px solid #000;
        padding: 10px 30px;
        color: #fff;
        font-family: 'EuropaSansRegular', sans-serif;
        font-feature-settings: 'ss02';
        font-size: 18px;
        text-transform: uppercase;
        background-color: #000;
        transition: color 0.3s, background-color 0.3s;
        cursor: pointer;

        &:hover {
            color: #000;
            background-color: #fff;
        }
    }
}

// Themes

body.theme-dark {
    // Global

    color: #fff;

    span,
    p,
    a {
        color: #fff;
    }

    input,
    button,
    textarea,
    select {
        color: #fff;
        border-color: #fff;
        background-color: #000;
    }

    // Anchor

    a {
        &:before {
            background-color: #ccc;
        }
    }
}
