.cta-caption-block {

    width: 100%;
    max-width: 1440px;
    margin: 120px auto;
    padding-left: 60px;
    padding-right: 60px;

    &.narrowWidth {
        max-width: 1024px;
        padding-left: 120px;
        padding-right: 120px;
    }

    .caption-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid #000;
        padding-top: 30px;
        font-family: 'EuropaSansLight', sans-serif;
    }

    .caption-heading {
        text-transform: uppercase;

        img {
            position: relative;
            top: -2px;
            vertical-align: middle;
            width: auto;
            height: 1em;
            border-radius: 50%;
            overflow: hidden;
        }
    }

    .information {
        img {
            position: relative;
            top: -2px;
            vertical-align: middle;
            width: auto;
            height: 1em;
            border-radius: 50%;
            overflow: hidden;
        }
    }

    .text,
    .link {
        width: calc(50% - 30px);
    }

    &.has-icon {
        .text {
            padding-top: 3px;
        }
    }

    .link {

        text-align: right;

        a {
            display: inline;
        }

        svg {
            position: relative;
            top: 3px;
            max-width: 22px;
            height: 1em;
        }

        a + span svg {
            margin-left: 10px;
        }

    }

    @media screen and (max-width: 767px) {

        margin-top: 60px;
        margin-bottom: 60px;
        padding-left: 30px;
        padding-right: 30px;

        &.narrowWidth {
            padding-left: 30px;
            padding-right: 30px;
        }

        .caption-container {
            flex-wrap: wrap;
        }

        .text,
        .link {
            width: 100%;
        }

        .link {
            margin-top: 15px;
            text-align: left;

            span {
                display: none;
            }

            a + span {
                display: inline-block;
            }
        }

    }

}

// Themes

body.theme-dark {

    .cta-caption-block {

        .caption-container {
            border-top-color: #fff;
        }

        .link svg path {
            fill: #fff;
        }

    }

}
